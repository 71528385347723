import { IDictionaryManifest } from '~/core/models';
import { importManifests } from '~/core/server/helpers/import-manifests';
import { importPackageJsons } from '~/core/server/helpers/import-package-jsons';

// import { alfaBankCoreManifests, alfaBankCorePackages } from './core-imports';

let widgetsManifests: IDictionaryManifest = {};

let packagesVersions: Record<string, string> = {};

export const initWidgets = (
    manifestsContext: Array<ReturnType<typeof require.context>>,
    packagesContext: Array<ReturnType<typeof require.context>> = []
) => {
    packagesVersions = Array.from(packagesContext).reduce(
        (acc, context) => ({ ...acc, ...importPackageJsons(context) }),
        {}
    );

    widgetsManifests = Array.from(manifestsContext).reduce(
        (acc, context) => ({ ...acc, ...importManifests(context, packagesVersions) }),
        {}
    );
};

export const getWidgetsManifests = () => widgetsManifests;
