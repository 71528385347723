import { PackageJson } from 'type-fest';
import privatePackageJson from '~/private/package.json';
import sideTeamPackageJson from '~/side-team/package.json';

import packageJson from '../../../../package.json';

const { dependencies } = packageJson;
/** в private командные пакеты */
const { dependencies: privateDependencies }: PackageJson = privatePackageJson;
/** в side-team приватные пакеты других команд  */
const { dependencies: sideTeamDependencies = {} }: PackageJson = sideTeamPackageJson;

const regexAlfaPackages = /^@alfa-bank\//;

/** функция возвращает список пакетов из package.json */
export const getAlfaPackages = () =>
    Object.keys({ ...dependencies, ...privateDependencies, ...sideTeamDependencies })
        .filter(key => regexAlfaPackages.test(key))
        .reduce((accum, key) => {
            const packageName = key.replace(regexAlfaPackages, '');

            return {
                ...accum,
                [packageName]: true
            };
        }, {});
